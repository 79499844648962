<template>
  <div>
    <slot />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Assessment",

  computed: {
    ...mapState("user", ["user"]),
  },
  watch: {
    loginRequired: function () {
      this.checkUser();
    },
    user: function () {
      this.checkUser();
    },
  },
  methods: {
    checkUser() {
      if (!this.user || this.user.isAnonymous)
        return this.$router.push("/community/login");
    },
  },
  mounted() {
    this.checkUser();
  },
};
</script>
